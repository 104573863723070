<app-dialog-header>
  {{ "Assign expats" | translate }}
  <div
    slot="subtitle"
    class="text-secondary font-medium text-base pt-1.5"
    *ngIf="showAlreadyAddedExpats()"
  >
    {{
      "PORTAL.SELECT-MORE-EXPATS" | translate: { expatCount: expatsRemaining() }
    }}
  </div>
</app-dialog-header>

<div class="dialog-body">
  <ng-container *ngFor="let controls of expatControlsArr">
    <app-offer-expat-item
      [form]="controls"
      [expatsList]="expatsList"
      [selectedExpatsList]="selectedExpatsList"
      class="w-full"
      [order]="order"
    ></app-offer-expat-item>
  </ng-container>
  <div
    *ngIf="
      totalNoExpats > 5 &&
      this.expatControls.controls.length < expatsRemaining()
    "
    class="w-full"
  >
    <button class="btn-outline-none-primary" (click)="addMoreExpats()">
      <mat-icon svgIcon="plus-circle" class="btn-icon"></mat-icon>
      {{ "Add more expats" | translate }}
    </button>
  </div>
</div>

<div class="dialog-footer">
  <button class="btn-primary" (click)="save('save')" [disabled]="isLoading">
    <mat-icon svgIcon="check-outline" class="btn-icon"></mat-icon>
    {{ "GENERALS.ASSIGN" | translate }}
  </button>
</div>
