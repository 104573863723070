<div [formGroup]="form" class="mb-2">
  <div class="flex gap-4">
    <mat-form-field class="w-full">
      <mat-label>{{ "GENERALS.EXPAT-NAME" | translate }} * </mat-label>
      <mat-select
        formPrefix="client"
        #singleSelect
        formControlName="expat"
        [placeholder]="'GENERALS.EXPAT-NAME' | translate"
        panelClass="offer-item-selector"
      >
        <mat-option>
          <ngx-mat-select-search
            [placeholderLabel]="'GENERALS.EXPAT-NAME' | translate"
            [noEntriesFoundLabel]="'GENERALS.NO-ITEMS-DISPLAY' | translate"
            [formControl]="expatSearchControl"
          ></ngx-mat-select-search>
        </mat-option>
        <mat-option [value]="'new_expat'">
          {{ "GENERALS.ADD-EXPATS" | translate }}
        </mat-option>
        <mat-option
          *ngFor="let expat of filteredExpats$ | async"
          [value]="expat.id"
        >
          {{ expat.name }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="form.controls.expat.errors?.required">
        {{ "GENERALS.REQUIRED-FIELD" | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label> {{ "LABEL.ASSIGNMENT" | translate }} </mat-label>
      <mat-select
        formControlName="assignment"
        [placeholder]="'LABEL.ASSIGNMENT' | translate"
        #singleSelect
        panelClass="offer-item-selector"
      >
        <mat-option>
          <ngx-mat-select-search
            [placeholderLabel]="'LABEL.ASSIGNMENT' | translate"
            [noEntriesFoundLabel]="'GENERALS.NO-ITEMS-DISPLAY' | translate"
            [formControl]="assignmentsSearchForm"
          ></ngx-mat-select-search>
        </mat-option>
        <mat-option *ngIf="form.get('expat').value" [value]="'new_assignment'">
          Add assignment
        </mat-option>
        <mat-option
          *ngFor="let assignments of filteredAssignments$ | async"
          [value]="assignments.id"
          [disabled]="checkAssignment(assignments.id)"
        >
          {{ assignments.home_country }} to {{ assignments.host_country }}, (
          {{ assignments.start_date | date: "dd/MM/yyyy" }} -
          {{ assignments.end_date | date: "dd/MM/yyyy" }} )
        </mat-option>
      </mat-select>
      <mat-error *ngIf="form.controls.assignment.errors?.required">
        {{ "GENERALS.REQUIRED-FIELD" | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label> {{ "LABEL.DEPENDENT" | translate }} </mat-label>
      <mat-select
        formControlName="dependent"
        [placeholder]="'LABEL.DEPENDENT' | translate"
        (opened)="dependentOptions.opened()"
      >
        <app-dependent-options
          #dependentOptions
          [expatId]="form.controls.expat.value"
        ></app-dependent-options>
      </mat-select>
    </mat-form-field>
  </div>
</div>
